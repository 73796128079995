import type { User } from 'firebase/auth'
import { useEffect } from 'react'
import { useAuth } from '@components/auth/store/auth.store'
import { defaultProfileImg } from '@components/ui/images/images'
import { useToast } from '@goatlab/react-ui'
import { useAccountStore } from '@sodium/shared-frontend-schemas'
import { firebaseAuthService } from '@src/services/firebase/firebaseAuth.service'

export const useFirebaseUser = () => {
  const { user, setUserFromFirebaseAuth, setToken } = useAuth()
  const { toast } = useToast()
  const { clearAccount } = useAccountStore()

  useEffect(() => {
    const onAuthStateChanged = async (currentUser: User | null) => {
      if (user?.account ?? !currentUser) {
        clearAccount()
        return
      }
      // Don't ask for the token again
      if (user?.token) {
        return
      }

      try {
        const token = await currentUser.getIdToken(true)

        if (currentUser && token) {
          setUserFromFirebaseAuth({
            email: currentUser.email ?? '',
            profilePicture: currentUser.photoURL || defaultProfileImg,
            displayName: currentUser.displayName ?? '',
            token,
            firebaseId: currentUser.uid,
          })

          setToken(token)
        }
      } catch (err: any) {
        toast({
          title: err.message,
          variant: 'destructive',
        })
        return
      }
    }

    return firebaseAuthService.getAuth()?.onAuthStateChanged(onAuthStateChanged)
  }, [user?.firebaseId])
}
