import type { FC } from 'react'
import { useUI } from '@contexts/ui/ui.context'
import { Modal } from './Modal'

export const ManagedModal: FC = () => {
  const { displayModal, closeModal, modalView, modalDismissible } = useUI()

  return (
    <Modal
      open={displayModal}
      onClose={closeModal}
      dismissible={modalDismissible}
    >
      {modalView}
    </Modal>
  )
}
