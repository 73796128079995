import { z } from 'zod'

export const optionsSchema = z.object({
  label: z.string(),
  value: z.string(),
})

export const seasonFiltersSchema = z.object({
  year: z.string().optional(),
  week: z.string().optional(),
  crop: z.string().optional(),
  market: z.string().optional(),
})

export const reportFiltersSchema = z.object({
  years: z.array(optionsSchema).optional(),
  weeks: z.array(optionsSchema).optional(),
  crops: z.array(optionsSchema).optional(),
  markets: z.array(optionsSchema).optional(),
})

export type Option = z.infer<typeof optionsSchema>

export type SeasonFilters = z.infer<typeof seasonFiltersSchema>

export type ReportFilters = z.infer<typeof reportFiltersSchema>
