import { z } from 'zod'
import { isPhoneNumberValid } from '../../util/isPhoneNumberValid'

export const personalInformationInputSchema = z.object({
  displayName: z.string().min(3).nullable(),
  firstName: z
    .string()
    .min(3, { message: 'Name must have at least 3 characters' })
    .optional()
    .nullable(),
  lastName: z
    .string({ required_error: 'Name is required' })
    .min(3, { message: 'Lastname must have at least 3 characters' })
    .optional()
    .nullable(),
  slug: z
    .string({ required_error: 'Username is required' })
    .min(3, { message: 'Username must have at least 3 characters' }),
  phoneNumber: z
    .string()
    .refine((phone) => isPhoneNumberValid(phone, true), {
      message: 'Please input a valid phone number',
    })
    .optional()
    .nullable(),
  title: z
    .string({ required_error: 'Lastname is required' })
    .min(3, { message: 'Title must have at least 3 characters' })
    .optional()
    .nullable(),
  profilePicture: z.string().optional().nullable(),
  backgroundImage: z.string().optional().nullable(),
  type: z.string().optional().nullable(),
  visibility: z.string().optional().nullable(),
})

export type PersonalInformationType = z.infer<
  typeof personalInformationInputSchema
>
