import { BackendHook, RouterOutputs } from "../../types/router";

export type UserStory =
  RouterOutputs["backend"]["stories"]["getStories"]["data"][number];
export type Story = UserStory["stories"][number];
export type UserStoriesMetaData = Story["metaData"];

export const useUserStoryQuery = ({
  backendHook,
}: {
  backendHook: BackendHook;
}) => {
  return backendHook.backend.stories.getStories.useInfiniteQuery(
    {
      perPage: 50,
    },
    {
      getNextPageParam: (page) => page.nextPage,
    }
  );
};
