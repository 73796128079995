// import libphonenumber from 'google-libphonenumber'

// const util = libphonenumber.PhoneNumberUtil.getInstance()
// TODO: fix the libphonenumber import, it is not working in the Edge Runtime
export const isPhoneNumberValid = (
  phoneNumber: string,
  isOptional = false,
): boolean => {
  if (phoneNumber.length <= 4 && isOptional) {
    return true
  }

  if (phoneNumber.length < 10) {
    return false
  }

  return true

  // return util.isValidNumber(util.parse(phoneNumber))
}
