import type { UserCredential } from 'firebase/auth'
import { env } from '@src/_env/env'
import { initializeApp } from 'firebase/app'
import {
  connectAuthEmulator,
  createUserWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  sendEmailVerification,
  signInWithCustomToken,
  signInWithPopup,
} from 'firebase/auth'

const config = {
  apiKey: env.FIREBASE_API_KEY,
  authDomain: env.FIREBASE_AUTH_DOMAIN,
  projectId: env.FIREBASE_PROJECT_ID,
  appId: env.FIREBASE_APP_ID,
  measurementId: env.FIREBASE_MEASUREMENT_ID,
}

const localConfig = {
  apiKey: 'PUT_IN_A_DUMMY_API_KEY',
  authDomain: env.FIREBASE_EMULATOR_URL,
  projectId: '',
  appId: '',
  measurementId: '',
}

const app = initializeApp(env.local ? localConfig : config)
const googleProvider = new GoogleAuthProvider()

const auth = getAuth(app)

if (env.local && env.FIREBASE_EMULATOR_URL) {
  connectAuthEmulator(auth, env.FIREBASE_EMULATOR_URL, {
    disableWarnings: true,
  })
}

class FirebaseAuthService {
  public getAuth = () => {
    return auth
  }

  public signInWithGoogle = async () => {
    try {
      return await signInWithPopup(this.getAuth(), googleProvider)
    } catch (err) {
      console.log(err)
      return null
    }
  }

  public signInWithToken = async (token: string) => {
    const userCredentials = await signInWithCustomToken(this.getAuth(), token)
    return userCredentials
  }

  public registerWithEmailAndPassword = async ({
    email,
    password,
  }: {
    email: string
    password: string
  }): Promise<UserCredential> => {
    const userCredentials = await createUserWithEmailAndPassword(
      this.getAuth(),
      email,
      password,
    )
    await sendEmailVerification(userCredentials.user)
    return userCredentials
  }

  logout = async (): Promise<void> => {
    await this.getAuth().signOut()
  }

  getNewToken = async (): Promise<string | undefined> => {
    return await this.getAuth().currentUser?.getIdToken(true)
  }

  isUserLoggedIn = () => {
    return this.getAuth().currentUser
  }
}
export const firebaseAuthService = new FirebaseAuthService()
