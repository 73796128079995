import { useEffect, useState } from 'react'
import { backendHook } from '@api/trpc'
import { useAuth } from '@components/auth/store/auth.store'

export const useSetTimezone = () => {
  const { user, setAccount: setStoredProfile } = useAuth()
  const [timezoneChecked, setTimezoneChecked] = useState(false)

  const updateTimeZone = backendHook.backend.account.updateTimeZone.useMutation(
    {
      onSuccess(timeZone) {
        if (user?.account) {
          setStoredProfile({ ...user.account, timeZone })
        }
      },
    },
  )

  useEffect(() => {
    // Avoid multiple calls to this method
    if (user?.account && !timezoneChecked && !updateTimeZone.isPending) {
      const currentTimeZone =
        Intl.DateTimeFormat().resolvedOptions().timeZone || 'America/Santiago'
      if (user.account.timeZone !== currentTimeZone) {
        setTimezoneChecked(true)
        updateTimeZone.mutate({ ...user.account, timeZone: currentTimeZone })
      }
    }
  }, [user, timezoneChecked, updateTimeZone])
}
