import { BackendHook } from '../../types/router'
import type { Post } from '../../types/Post'
import { homeFeedQueryInput } from './homeFeed/homeFeedQueryInput'
import { produce, Draft } from 'immer'
import { getAccountFeedQueryInput } from './accountFeed/accountFeedQueryInput'
import { getAccountsThatLikedPostInput } from './getAccountThatLikedPost/getAccountThatLikedPostInput'

export class PostCache {
  private utils: ReturnType<BackendHook['useUtils']>

  constructor(utils: ReturnType<BackendHook['useUtils']>) {
    this.utils = utils
  }

  async updateById({
    postId,
    visibleAccountId,
    updateCallback,
  }: {
    postId: string
    visibleAccountId?: string
    updateCallback: (previousState: Draft<Post>) => void
  }) {
    const accountFeedInput = getAccountFeedQueryInput({
      accountId: visibleAccountId,
    })

    await this.utils.backend.posts.homeFeed.cancel(homeFeedQueryInput)
    await this.utils.backend.posts.accountFeed.cancel(accountFeedInput)
    // Modify Home Feed
    this.utils.backend.posts.homeFeed.setInfiniteData(
      homeFeedQueryInput,
      (previousData) => {
        if (!previousData?.pages?.length) {
          return { pageParams: [], pages: [] }
        }

        return produce(previousData, (draft) => {
          for (const page of draft.pages) {
            const post = page.data.find((p) => p.id === postId)

            if (post) {
              updateCallback(post)
              break
            }
          }
        })
      }
    )

    // Modify Account Feed
    if (visibleAccountId) {
      this.utils.backend.posts.accountFeed.setInfiniteData(
        accountFeedInput,
        (previousData) => {
          if (!previousData?.pages?.length) {
            return { pageParams: [], pages: [] }
          }

          return produce(previousData, (draft) => {
            for (const page of draft.pages) {
              const post = page.data.find((p) => p.id === postId)
              if (post) {
                updateCallback(post)
                break
              }
            }
          })
        }
      )
    }
  }

  async setAccountsThatLikedPost({
    postId,
    updateCallback,
  }: {
    postId: string
    updateCallback: (draftPage: Draft<any[]>) => void
  }) {
    const accountsThatLikedPostInput = getAccountsThatLikedPostInput({
      postId,
    })

    await this.utils.backend.posts.getAccountsThatLikedPost.cancel()
    // Force fetch to get the latest data
    await this.utils.backend.posts.getAccountsThatLikedPost.fetchInfinite(
      accountsThatLikedPostInput
    )

    this.utils.backend.posts.getAccountsThatLikedPost.setInfiniteData(
      accountsThatLikedPostInput,
      (previousData) => {
        return produce(previousData, (draft) => {
          if (!draft) {
            return previousData
          }

          return updateCallback(draft.pages)
        })
      }
    )
  }

  addPost(post: Post) {}
}
