import { BackendHook } from '../../../types/router'
import { getAccountConnectionsInput } from './getAccountConnectionsInput'

export const useGetAccountConnections = ({
  accountId,
  backendHook,
}: {
  backendHook: BackendHook
  accountId?: string
}): ReturnType<BackendHook['backend']['friendship']['getFollowers']['useInfiniteQuery']>  => {
  const input = getAccountConnectionsInput({ accountId })
  const enabled = !!accountId

  return backendHook.backend.friendship.getFollowers.useInfiniteQuery(input, {
    enabled,
    getNextPageParam: (lastPage) => lastPage?.nextPage,
  })
}
