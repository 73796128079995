import { useTypedTranslation } from '@goatlab/marketplace-i18n'

export const useReportOptions = () => {
  const { t } = useTypedTranslation(['report'])

  return [
    { value: 'dont_like_it', label: t('dont_like_it') },
    { value: 'spam', label: t('spam') },
    { value: 'nudity_sexual_activity', label: t('nudity_sexual_activity') },
    { value: 'hate_speech_symbols', label: t('hate_speech_symbols') },
    { value: 'false_information', label: t('false_information') },
    { value: 'bullying_harassment', label: t('bullying_harassment') },
    { value: 'violence_dangerous_orgs', label: t('violence_dangerous_orgs') },
    { value: 'scam_fraud', label: t('scam_fraud') },
    { value: 'illegal_regulated_goods', label: t('illegal_regulated_goods') },
    { value: 'suicide_self_injury', label: t('suicide_self_injury') },
    { value: 'eating_disorders', label: t('eating_disorders') },
  ]
}
