import { RouterInputs } from '../../../types/router'

export type GetAccountConnections =
  RouterInputs['backend']['friendship']['getFollowers']

export const getAccountConnectionsDefault: GetAccountConnections = {
  perPage: 30,
  accountId: '',
}

export const getAccountConnectionsInput = ({
  accountId = '',
}: {
  accountId?: string
}): GetAccountConnections => {
  return {
    ...getAccountConnectionsDefault,
    accountId,
  }
}
