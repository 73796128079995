import { CommonLogger } from '@goatlab/js-utils'
import type { BackendHook } from '../../../types/router'
import { Account } from '../../../types/Account'

import { PostCache } from '../PostCache'

export const useTogglePostLike = ({
  backendHook,
  account,
  logger,
  visibleAccountId,
}: {
  backendHook: BackendHook
  visibleAccountId?: string
  account?: Account
  logger: CommonLogger
}) => {
  const utils = backendHook.useUtils()
  const postCache = new PostCache(utils)

  return backendHook.backend.posts.togglePostLike.useMutation({
    onMutate: async (newLike) => {
      let userAlreadyLikedPost: boolean = false

      await postCache.updateById({
        postId: newLike.postId,
        visibleAccountId,
        updateCallback: (post) => {
          userAlreadyLikedPost = post.liked
          post.reactionsCount = String(
            (Number(post.reactionsCount) || 0) + (post.liked ? -1 : 1)
          )

          post.liked = !post.liked
        },
      })

      await postCache.setAccountsThatLikedPost({
        postId: newLike.postId,
        updateCallback: (pages) => {
          // Add the user to the list of accounts that liked the post
          if (!userAlreadyLikedPost) {
            pages[0].data.unshift({
              id: account?.id || '',
              displayName: account?.displayName || '',
              profilePicture: account?.profilePicture || '',
              title: account?.title || '',
              slug: account?.slug || '',
            })
            return
          }
          // Search for the post and filter it out
          for (const page of pages) {
            page.data = page.data.filter(
              (likeAccount: any) => likeAccount.id !== account?.id
            )
          }
        },
      })
    },
    onError: (err, newLike, context) => {
      logger.error(err)
      // ROLL BACK any changes made to the data
      postCache.updateById({
        postId: newLike.postId,
        visibleAccountId,
        updateCallback: (post) => {
          post.reactionsCount = String(
            (Number(post.reactionsCount) || 0) + (post.liked ? 1 : -1)
          )

          post.liked = !post.liked
        },
      })
    },
  })
}
