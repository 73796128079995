import { BackendHook } from '../../../types/router'
import { homeFeedQueryInput } from './homeFeedQueryInput'

export const useHomeFeedQuery = ({
  backendHook,
}: {
  backendHook: BackendHook
}): ReturnType<BackendHook['backend']['posts']['homeFeed']['useInfiniteQuery']>  => {
  return backendHook.backend.posts.homeFeed.useInfiniteQuery(
    homeFeedQueryInput,
    {
      enabled: true,
      getNextPageParam: (lastPage) => {
        return lastPage.nextPage
      },
    }
  )
}
