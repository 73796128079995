
import { CommonLogger } from '@goatlab/js-utils'
import { getPostCommentsQueryInput } from './getPostCommentsQueryInput'
import { useHomeFeedStore } from '@sodium/shared-frontend-schemas'
import { PostCache } from '../PostCache'
import { BackendHook } from '@src/types/router'
import { ActivityItem } from '@src/types/Activity'
import { DeepPartial } from '@src/types/DeepPartial'

export const useDeletePostComment = ({
  backendHook,
  accountId,
  reset,
  logger,
}: {
  accountId?: string
  backendHook: BackendHook
  logger: CommonLogger
  reset?: () => Promise<void>
})  : ReturnType<BackendHook['backend']['posts']['deletePostComment']['useMutation']> => {
  const utils = backendHook.useUtils()
  const { visibleAccountId } = useHomeFeedStore()
  const postCache = new PostCache(utils)

  return backendHook.backend.posts.deletePostComment.useMutation({
    onMutate: async (deletedComment) => {
      let previousComment: DeepPartial<ActivityItem>  = {}

      postCache.updateById({
        postId: deletedComment.postId,
        visibleAccountId,
        updateCallback: (post) => {
          post.commentsCount = String((Number(post.commentsCount) || 0) - 1)
        },
      })

      utils.backend.posts.getPostComments.setInfiniteData(
        getPostCommentsQueryInput({
          postId: deletedComment.postId,
        }),
        (previousComments) => {
          if (!previousComments?.pages?.length) {
            return { pageParams: [], pages: [] }
          }
          return {
            ...previousComments,
            // From every page
            pages: previousComments.pages.map((page) => ({
              ...page,
              // Filter the list of comments so we
              // can also remove it there
              data: page.data.filter((comment) => {
                const isCommentToDelete =
                  String(comment.id) === String(deletedComment.commentId)

                return !isCommentToDelete
              }),
            })),
          }
        }
      )

      reset?.()

      return { previousComment }
    },
    onError: async (err, deletedComment, context) => {
      logger.error(err)

      await postCache.updateById({
        postId: deletedComment.postId,
        visibleAccountId,
        updateCallback: (post) => {
          post.commentsCount = String((Number(post.commentsCount) || 0) + 1)
        },
      })

      utils.backend.posts.getPostComments.setInfiniteData(
        getPostCommentsQueryInput({
          postId: deletedComment.postId,
        }),
        (previousComments) => {

          if (!previousComments?.pages?.length) {
            return { pageParams: [], pages: [] }
          }

          return {
            ...previousComments,
            // From every page
            pages: previousComments.pages.map((page) => ({
              ...page,
              // Filter the list of comments so we
              // can also remove it there
              data: page.data.map((comment) => {
                const isCommentToDelete =
                  String(comment.id) === String(context?.previousComment?.id)

                if (isCommentToDelete) {
                  return context?.previousComment as typeof comment
                }

                return comment
              }),
            })),
          }
        }
      )
    },
  })
}
