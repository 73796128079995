import { z } from "zod";

export const usdaFiltersSchema = z.object({
  commodity: z.string(),
  years: z.array(z.string()),
  weeks: z.array(z.string()),
  variety: z.string(),
  size: z.string(),
  district: z.string(),
});

export const usdaReportFiltersSchema = z.object({
  years: z.array(z.string()),
  weeks: z.array(z.string()),
  commodities: z.array(z.string()).optional(),
  varieties: z.array(z.string()).optional(),
  sizes: z.array(z.string()).optional(),
  districts: z.array(z.string()).optional(),
});

export type USDAFilters = z.infer<typeof usdaFiltersSchema>;

export type USDAArrayFilters = z.infer<typeof usdaReportFiltersSchema>;
