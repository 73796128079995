import { CreateTRPCNext } from '@trpc/next'
import type { ServiceAppRouter, BackendHook } from './types/router'
import { CommonLogger } from '@goatlab/js-utils'
import { posts } from './api/posts/posts'
import { stories } from './api/stories/stories'
import { hooks } from './hooks/hooks'

export class FrontendApi {
  backendHookNext: CreateTRPCNext<ServiceAppRouter, any> | undefined = undefined
  backendHookReact: BackendHook | undefined = undefined
  backendHook: BackendHook | undefined = undefined
  logger: CommonLogger = console
  posts: ReturnType<typeof posts>
  stories: ReturnType<typeof stories>
  hooks: ReturnType<typeof hooks>

  constructor({
    backendHookNext,
    backendHookReact,
    logger,
  }: {
    backendHookNext?: CreateTRPCNext<ServiceAppRouter, any>
    backendHookReact?: BackendHook
    logger?: CommonLogger
  }) {
    this.backendHookNext = backendHookNext
    this.backendHookReact = backendHookReact
    this.logger = logger || console

    if (this.backendHookNext) {
      this.backendHook = this.backendHookNext as unknown as BackendHook
    }

    if (this.backendHookReact) {
      this.backendHook = this.backendHookReact
    }

    this.posts = posts({
      backendHook: this.backendHook!,
      logger: this.logger!,
    })

    this.stories = stories({
      backendHook: this.backendHook!,
      logger: this.logger!,
    })

    this.hooks = hooks({
      backendHook: this.backendHook!,
      logger: this.logger!,
    })
  }
}
