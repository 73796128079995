import { useZodFormHook, useZustandForm } from '@goatlab/react-zod-form'
import {
  profileInformationInputSchema,
  ProfileInformationType,
} from '../schema/profileInformationInputSchema'

type ProfileInfoProps = {
  defaultValues?: ProfileInformationType
}

export const useProfileInformationForm = ({
  defaultValues,
}: ProfileInfoProps) => {
  const zodForm = useZustandForm(profileInformationInputSchema)

  const values = defaultValues || zodForm.formValues

  const formHook = useZodFormHook({
    schema: profileInformationInputSchema,
    defaultValues: {
      birthDate: values.birthDate || '',
      sex: values.sex || '',
      quickBio: values.quickBio || '',
      current_company_name: values.current_company_name || '',
      current_position_name: values.current_position_name || '',
      website: values.website || '',
    },
  })

  return { zodForm, formHook }
}
