import type { Product } from '@src/api/settings/types'
import type { ComponentProps, ReactNode } from 'react'
import dynamic from 'next/dynamic'
import { getToken } from '@src/api/http/getToken'
import { create } from 'zustand'

const AvailabilityModal = dynamic(() =>
  import('@components/account/settings/availability/AddAvailabilityModal').then(
    (mod) => mod.AddAvailabilityModal,
  ),
)

const LoginForm = dynamic(() =>
  import('@components/auth/LoginForm').then((mod) => mod.LoginForm),
)

const BookAppointMentModal = dynamic(() =>
  import('@components/staff/bookAppointmentModal').then(
    (mod) => mod.BookAppointMentModal,
  ),
)

const CreateOrganizationWizard = dynamic(() =>
  import('@components/account/create/CreateAccountWizard').then(
    (mod) => mod.CreateAccountWizard,
  ),
)
const ProductPopup = dynamic(() =>
  import('@components/product/product-popup').then((mod) => mod.default),
)
const ConfirmationModal = dynamic(() =>
  import('@components/ui/notifications/ConfirmationModal').then(
    (mod) => mod.ConfirmationModal,
  ),
)

export interface UIState {
  isAuthorized: boolean
  displaySidebar: boolean
  displayFilter: boolean
  displayModal: boolean
  displayShop: boolean
  displayCart: boolean
  displaySearch: boolean
  displayTemplateSelector: boolean
  modalView: ReactNode
  modalData: any
  modalTitle?: string | null
  modalDismissible: boolean
  drawerView: string | null
  toastText: string
  userAvatar: string
  accountSideBarOpen: boolean
  displayingOnboarding: boolean
  isFullScreenModal?: boolean
  inboxSidebarOpen: boolean
}

const initialUIState: UIState = {
  isAuthorized: !!getToken(),
  displaySidebar: false,
  displayFilter: false,
  displayModal: false,
  displayShop: false,
  displayCart: false,
  displaySearch: false,
  displayTemplateSelector: false,
  modalView: null,
  drawerView: null,
  modalData: null,
  modalTitle: null,
  isFullScreenModal: false,
  modalDismissible: true,
  toastText: '',
  userAvatar: '',
  accountSideBarOpen: true,
  displayingOnboarding: false,
  inboxSidebarOpen: true,
}

type DrawerViews = 'CART_SIDEBAR' | 'MOBILE_MENU'

export interface UIActions {
  setAuthorized: () => void
  setUnAuthorized: () => void
  openSideBar: () => void
  closeSideBar: () => void
  openCart: () => void
  closeCart: () => void
  openSearch: () => void
  closeSearch: () => void
  setToastText: (text: string) => void
  openFilter: () => void
  closeFilter: () => void
  openShop: () => void
  closeShop: () => void
  openModal: () => void
  closeModal: () => void
  openTemplateSelector: () => void
  closeTemplateSelector: () => void
  setModalView: (view: ReactNode) => void
  setDrawerView: (view: DrawerViews) => void
  setModalData: (data: Record<string, any>) => void
  setModalTitle: (title: string) => void
  setModalDismissible: (dismissible: boolean) => void
  setUserAvatar: (url: string) => void
  setAccountSidebarOpen: (open: boolean) => void
  setDisplayingOnboarding: (open: boolean) => void
  setIsFullScreenModal: (fullScreen: boolean) => void
  setInboxSidebarOpen: (open: boolean) => void
  Modal: {
    open: () => void
    close: () => void
    openAvailability: () => void
    openLogin: () => void
    openProductPreview: (params: { product: Product }) => void
    openBookAppointment: () => void
    openCreateOrganization: () => void
    requireConfirmation: (
      props: ComponentProps<typeof ConfirmationModal>,
    ) => void
  }
}

export const useUI = create<UIState & UIActions>((set, store) => {
  return {
    ...initialUIState,
    Modal: {
      open() {},
      close() {
        store().closeModal()
      },
      openAvailability() {
        store().setModalView(<AvailabilityModal />)
        store().openModal()
      },
      requireConfirmation(props) {
        store().setModalView(<ConfirmationModal {...props} />)
        store().openModal()
      },
      openLogin() {
        store().setModalView(<LoginForm />)
        store().openModal()
      },
      openProductPreview({ product }) {
        store().setModalData({ data: product })
        store().setModalView(<ProductPopup />)
        store().openModal()
      },
      openBookAppointment() {
        store().setModalView(<BookAppointMentModal />)
        store().openModal()
      },
      openCreateOrganization() {
        store().setModalTitle('Create Organization')
        store().setModalView(<CreateOrganizationWizard />)
        store().openModal()
      },
    },
    setInboxSidebarOpen(open) {
      set({ inboxSidebarOpen: open })
    },
    setModalTitle(title) {
      set({ modalTitle: title })
    },
    setAuthorized() {
      set({ isAuthorized: true })
    },
    setUnAuthorized() {
      set({ isAuthorized: false })
    },
    openSideBar() {
      set({ displaySidebar: true })
    },
    closeSideBar() {
      set({ displaySidebar: false })
    },
    openCart() {
      set({ displayCart: true })
    },
    closeCart() {
      set({ displayCart: false })
    },
    openSearch() {
      set({ displaySearch: true })
    },
    closeSearch() {
      set({ displaySearch: false })
    },
    openTemplateSelector() {
      set({ displayTemplateSelector: true })
    },
    closeTemplateSelector() {
      set({ displayTemplateSelector: false })
    },
    openFilter() {
      set({ displayFilter: true })
    },
    closeFilter() {
      set({ displayFilter: false })
    },
    openShop() {
      set({ displayShop: true })
    },
    closeShop() {
      set({ displayShop: false })
    },
    openModal() {
      set({ displayModal: true })
    },
    closeModal() {
      set({
        displayModal: false,
        modalDismissible: true,
        modalData: undefined,
        modalTitle: null,
        isFullScreenModal: false,
      })
    },
    setModalDismissible(dismissible) {
      set({ modalDismissible: dismissible })
    },
    setIsFullScreenModal(fullScreen) {
      set({ isFullScreenModal: fullScreen })
    },
    setModalView(modalView) {
      set({ modalView })
    },
    setDrawerView(drawerView) {
      set({ drawerView })
    },
    setModalData(modalData) {
      set({ modalData })
    },
    setToastText(toastText) {
      set({ toastText })
    },
    setUserAvatar(userAvatar) {
      set({ userAvatar })
    },
    setAccountSidebarOpen(open) {
      set({ accountSideBarOpen: open })
    },
    setDisplayingOnboarding(displaying) {
      set({ displayingOnboarding: displaying })
    },
  }
})
