import { BackendHook } from "../../../types/router";
import { getAdvertisingAccountFeedQueryInput } from "./advertisingAccountFeedQueryInput";

export const useAdvertisingAccountFeedQuery = ({
  backendHook,
}: {
  backendHook: BackendHook;
}) => {
  const input = getAdvertisingAccountFeedQueryInput();

  return backendHook.backend.posts.advertisingAccountFeed.useInfiniteQuery(
    input,
    {
      enabled: true,
      getNextPageParam: (lastPage) => lastPage?.nextPage,
    }
  );
};
