import { z } from 'zod'

export const profileInformationInputSchema = z.object({
  birthDate: z.string().optional(),
  sex: z.string().optional(),
  quickBio: z.string().optional(),
  current_company_name: z.string().optional(),
  current_position_name: z.string().optional(),
  website: z.string().optional(),
})

export type ProfileInformationType = z.infer<
  typeof profileInformationInputSchema
>
