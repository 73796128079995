import { RouterInputs } from "../../../types/router";

export type AdvertisingAccountFeedQueryInput =
  RouterInputs["backend"]["posts"]["advertisingAccountFeed"];

export const advertisingAccountFeedQueryInput: AdvertisingAccountFeedQueryInput =
  {
    perPage: 30,
    q: "",
  };

export const getAdvertisingAccountFeedQueryInput =
  (): AdvertisingAccountFeedQueryInput => {
    return {
      ...advertisingAccountFeedQueryInput,
    };
  };
