import type { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { DefaultSeo } from '@components/common/default-seo'
import { ManagedModal } from '@components/common/modal/ManagedModal2'
import { backendHook } from '@src/api/trpc'
import { useFirebaseUser } from '@src/hooks/useFirebaseUser'
import { useHtmlDirection } from '@src/hooks/useHtmlDirection'
import { useSentry } from '@src/hooks/useSentry'
import { useSetSite } from '@src/hooks/useSetSite'
import { useSetTimezone } from '@src/hooks/useSetTimezone'
import { useUserAccountAndRoles } from '@src/hooks/useUserAccountAndRoles'
import { Analytics } from '@vercel/analytics/react'
import './styles.scss'

const Progress = dynamic(
  () => import('@components/ui/PageProgress').then((mod) => mod.PageProgress),
  { ssr: false },
)

const Toaster = dynamic(
  () => import('@goatlab/react-ui').then((mod) => mod.Toaster),
  { ssr: false },
)

const ToasterSonner = dynamic(
  () => import('@goatlab/react-ui').then((mod) => mod.ToasterSonner),
  { ssr: false },
)

const ManagedDrawer = dynamic(
  () => import('@components/common/drawer/managed-drawer'),
  { ssr: false },
)

const GealiumApp = ({ Component, pageProps }: AppProps) => {
  const router = useRouter()

  useSentry()
  // useFullTailwind()
  useHtmlDirection()
  useFirebaseUser()
  useUserAccountAndRoles()
  useSetSite()
  useSetTimezone()

  return (
    <>
      <Progress />
      <DefaultSeo />
      <Component {...pageProps} key={router.route} />
      <ToasterSonner />
      <Toaster />
      <ManagedDrawer />
      <ManagedModal />
      <Analytics />
    </>
  )
}

export default backendHook.withTRPC(GealiumApp)
