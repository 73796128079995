import { ActivityItem } from '@src/types/Activity'
import { getPostCommentsQueryInput } from './getPostCommentsQueryInput'
import { CommonLogger } from '@goatlab/js-utils'
import { BackendHook } from '@src/types/router'
import { DeepPartial } from '@src/types/DeepPartial'

export const useUpdateComment = ({
  backendHook,
  reset,
  logger,
}: {
  backendHook: BackendHook
  logger: CommonLogger
  reset?: () => Promise<void>
}): ReturnType<BackendHook['backend']['posts']['updatePostComment']['useMutation']> => {
  const utils = backendHook.useUtils()

  return backendHook.backend.posts.updatePostComment.useMutation({
    onMutate: async (updatedComment) => {
      let previousComment: DeepPartial<ActivityItem>  = {}
      // Optimistically update the individual comment list for the activity
      utils.backend.posts.getPostComments.setInfiniteData(
        getPostCommentsQueryInput({
          postId: updatedComment.postId,
        }),
        (previousComments) => {
          if (!previousComments?.pages?.length) {
            return { pageParams: [], pages: [] }
          }

          previousComments.pages = previousComments.pages.map((page) => {
            page.data = page.data.map((comment) => {
              if (comment.id !== updatedComment.commentId) {
                return comment
              }
              previousComment = { ...comment }

              comment.content = updatedComment.content
              comment.updated = new Date().toISOString()
              return comment
            })

            return page
          })

          return previousComments
        }
      )

      await reset?.()

      return { previousComment }
    },
    onError: (error, updatedComment, context) => {
      logger.error(error)

      if (!context?.previousComment) {
        return
      }

      utils.backend.posts.getPostComments.setInfiniteData(
        getPostCommentsQueryInput({
          postId: updatedComment.postId,
        }),
        (previousComments) => {
          if (!previousComments?.pages?.length) {
            return { pageParams: [], pages: [] }
          }

          // To trigger the refresh we must
          // spread the previous comments
          return {
            ...previousComments,
            // From every page
            pages: previousComments.pages.map((page) => ({
              ...page,
              // Filter the list of comments so we
              // can also remove it there
              data: page.data.map((comment) => {
                const isCommentToUpdate =
                  comment.id === context?.previousComment?.id

                if (!isCommentToUpdate) {
                  return comment
                }

                return context?.previousComment as typeof comment
              }),
            })),
          }
        }
      )
    },
  })
}
