import { z } from 'zod'

export const reportSchema = z.object({
  reason: z.string().min(1, { message: 'You must select a reason' }),
  accountId: z.string(),
  postId: z.string(),
  commentId: z.string(),
})

export type ReportType = z.infer<typeof reportSchema>
