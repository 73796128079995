import { RouterInputs } from '../../../types/router'

export type AccountFeedQueryInput =
  RouterInputs['backend']['posts']['accountFeed']

export const homeFeedQueryInput: AccountFeedQueryInput = {
  perPage: 30,
  q: '',
  accountId: '',
}

export const getAccountFeedQueryInput = ({
  accountId,
}: {
  accountId?: string
}): AccountFeedQueryInput => {
  return {
    ...homeFeedQueryInput,
    accountId: accountId || '',
  }
}
