import { create } from "zustand";

export interface MarketplaceAsset {
  url: string;
  originalName: string;
  mimeType: string;
  isImage: boolean;
  isVideo: boolean;
  isFile: boolean;
  height?: number;
  width?: number;
  isVertical?: boolean;
  sizeBytes?: number;
  thumbnailUrl?: string;
  duration?: number;
  pages?: number;
  storyId?: string;
}

export interface NewPostState {
  renderKey: string;
  index: number;
  postId?: string;
  repostId?: string;
  content: string;
  assets: MarketplaceAsset[];
  mentionedAccountIds: string[];
  isReOrderingAssets: boolean;
  isPreviewing?: boolean;
  isLoadingMedia?: boolean;
  isPosting?: boolean;
  isAdvertisingPost?: boolean;
  hasAssets?: boolean;
  hasSeveralAssets?: boolean;
  postHasContent?: boolean;
  isReadyToPost?: boolean;
}

export interface NewPostAction {
  setContent: (content?: string) => void;
  addAsset: (asset: MarketplaceAsset) => void;
  addMentionedAccountIds: (mentionedAccountId: string) => void;
  replaceAsset: (asset: MarketplaceAsset, newAsset: MarketplaceAsset) => void;
  setAssets: (asset: MarketplaceAsset[]) => void;
  setMentionedAccountIds: (mentionedAccountIds: string[]) => void;
  setIsReorderingAssets: (isReOrderingAssets: boolean) => void;
  toggleReorderingAssets: () => void;
  resetKey: () => void;
  reset: () => void;
  setIsLoadingMedia: (isLoadingMedia: boolean) => void;
  setIsPosting: (isPosting: boolean) => void;
  setIsAdvertisingPost: (isAdvertisingPost: boolean) => void;
  setIsPreviewing: (isPreviewing: boolean) => void;
  setIndex: (index: number) => void;
  setPostId: (postId: string) => void;
  setRepostId: (repostId?: string) => void;
}

const defaultState: NewPostState = {
  renderKey: "1",
  index: 0,
  postId: undefined,
  repostId: undefined,
  isPreviewing: false,
  hasAssets: false,
  postHasContent: false,
  hasSeveralAssets: false,
  isReadyToPost: false,
  content: "",
  assets: [],
  mentionedAccountIds: [],
  isReOrderingAssets: false,
  isLoadingMedia: false,
  isPosting: false,
  isAdvertisingPost: false,
};

export const useNewPostStore = create<NewPostState & NewPostAction>((set) => ({
  ...defaultState,
  setIndex: (index) => {
    if (index === 0) {
      set({ index, isPreviewing: false });
      return;
    }
    set({ index, isPreviewing: true });
  },
  setPostId: (postId) => {
    set({ postId });
  },
  setRepostId: (repostId) => {
    set({ repostId });
  },
  setContent: (content?: string) => {
    const postHasContent =
      !!content && content.length > 0 && content !== "<p></p>";

    set({ content: content || "", postHasContent });
  },
  setIsPosting: (isPosting) => {
    set({ isPosting });
  },
  setIsAdvertisingPost: (isAdvertisingPost) => {
    set({ isAdvertisingPost });
  },
  setIsPreviewing: (isPreviewing) => {
    set({ isPreviewing });
  },
  toggleReorderingAssets: () => {
    set((prevState) => {
      return {
        isReOrderingAssets: !prevState.isReOrderingAssets,
      };
    });
  },
  addAsset: (newAsset) => {
    set((prevState) => {
      const assets = [...prevState.assets, newAsset];
      const hasAssets = assets && assets.length > 0;
      const hasSeveralAssets = hasAssets && assets.length > 1;

      return {
        assets,
        hasAssets,
        hasSeveralAssets,
      };
    });
  },
  addMentionedAccountIds: (newMentionedAccountId) => {
    set((prevState) => {
      const { mentionedAccountIds } = prevState;

      if (mentionedAccountIds.includes(newMentionedAccountId)) {
        return prevState;
      }

      const updatedMentionedAccountIds = Array.from(
        new Set([...mentionedAccountIds, newMentionedAccountId])
      );

      return { mentionedAccountIds: updatedMentionedAccountIds };
    });
  },
  setAssets: (assets) => {
    const hasAssets = assets && assets.length > 0;
    const hasSeveralAssets = hasAssets && assets.length > 1;
    set({ assets, hasAssets, hasSeveralAssets });
  },
  setMentionedAccountIds: (mentionedAccountIds) => {
    set(() => {
      const uniqueMentionedAccountIds = Array.from(
        new Set(mentionedAccountIds)
      );

      return { mentionedAccountIds: uniqueMentionedAccountIds };
    });
  },
  setIsLoadingMedia: (isLoadingMedia) => {
    set({ isLoadingMedia });
  },
  setIsReorderingAssets: (isReOrderingAssets) => {
    set({ isReOrderingAssets });
  },
  replaceAsset: (asset, newAsset) => {
    set((state) => {
      const assets = state.assets.map((a) =>
        a.url === asset.url
          ? {
              ...{
                ...a,
                ...newAsset,
              },
              url: newAsset.url,
            }
          : a
      );
      return { assets };
    });
  },
  resetKey: () => {
    set({ renderKey: Math.random().toString(36).substring(2, 8) });
  },
  reset: () => {
    set({
      ...defaultState,
      renderKey: Math.random().toString(36).substring(2, 8),
    });
  },
}));
