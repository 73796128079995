import { z } from 'zod'

export const statisticsFiltersSchema = z.object({
  market: z.string(),
  crop: z.string(),
  seasons: z.array(z.string()),
  week: z.string(),
})

export type StatisticsFilters = z.infer<typeof statisticsFiltersSchema>
