import { countries, ICountry } from 'countries-list'

export const useCountryOptions = () => {
  const options = []

  for (const countryCode in countries) {
    if (countries.hasOwnProperty(countryCode)) {
      const country = (countries as any)[countryCode] as unknown as ICountry

      options.push({ label: country.name, value: countryCode })
    }
  }
  return options
}
