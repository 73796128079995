import { createTRPCReact } from '@trpc/react-query'
import {
  CreateTRPCClient,
  HTTPBatchLinkOptions,
  TRPCLink,
  createTRPCClient,
  httpBatchLink,
  loggerLink,
} from '@trpc/client'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { createTRPCNext } from '@trpc/next'
import { parseJwt } from './util/parseJwt'
import type { ServiceAppRouter } from './types/router'

type TrpcParams = {
  getExistingToken: () => Promise<string>
  getNewToken: () => Promise<string | undefined>
  setToken: (token: string) => Promise<void>
  removeToken: () => Promise<void>
  isUserLoggedIn: () => Promise<boolean>
  onInvalidTokenDetected: (decodedToken: Record<string, any>) => Promise<void>
  showLogs?: boolean
  userAgent?: string
  baseApiUrl: string
}

export const getTrpc = ({
  getExistingToken,
  getNewToken,
  setToken,
  isUserLoggedIn,
  onInvalidTokenDetected,
  baseApiUrl,
  showLogs,
  userAgent,
}: TrpcParams): {
  backendRequest: CreateTRPCClient<ServiceAppRouter>;
  backendHook: ReturnType<typeof createTRPCReact<ServiceAppRouter>>;
  backendHookNext: ReturnType<typeof createTRPCNext<ServiceAppRouter>>;
  TRPCProvider: (props: { children: React.ReactNode }) => JSX.Element;
  queryClient: QueryClient;
} => {
  /**
   * Configuration for the TRPC client
   */
  const trpcClientSettings: HTTPBatchLinkOptions<any> = {
    url: `${baseApiUrl}/trpc`,
    async headers(): Promise<Record<string, any>> {
      const accessToken = await getExistingToken()

      if (!accessToken) {
        return {}
      }

      const decoded = parseJwt(accessToken)
      const isTokenExpired = decoded.exp < (Date.now() + 1) / 1000

      if (!isTokenExpired) {
        return {
          authorization: `Bearer ${accessToken}`,
          'user-agent': userAgent,
        }
      }

      // Token expired and we cant refresh it
      if (!isUserLoggedIn()) {
        onInvalidTokenDetected(decoded)
        return {}
      }

      const newToken = await getNewToken()

      if (!newToken) {
        throw new Error('Could not refresh user Token')
      }
      setToken(newToken)

      return {
        authorization: `Bearer ${newToken}`,
      }
    },
  }

  const ServiceLink: TRPCLink<ServiceAppRouter> = (runtime) => {
    const servers = {
      backend: httpBatchLink(trpcClientSettings)(runtime),
      commerce: httpBatchLink({
        ...trpcClientSettings,
        url: `${baseApiUrl}/commerce/trpc`,
      })(runtime),
      link: httpBatchLink({
        ...trpcClientSettings,
        url: `${baseApiUrl}/link/trpc`,
      })(runtime),
      social: httpBatchLink({
        ...trpcClientSettings,
        url: `${baseApiUrl}/social/trpc`,
      })(runtime),
    }

    return (ctx) => {
      const { op } = ctx
      // split the path by `.` as the first part will signify the server target name
      const pathParts = op.path.split('.')

      type ServerName = keyof typeof servers
      // Default to backend, if the call has no server specified
      let serverName: ServerName = 'backend'

      // Dynamically check if the first part of the path is a valid server key
      if (pathParts[0] && Object.keys(servers).includes(pathParts[0])) {
        serverName = pathParts.shift() as ServerName
      }

      // combine the rest of the parts of the paths
      // -- this is what we're actually calling the target server with
      const path = pathParts.join('.')
      const link = servers[serverName]

      return link({
        ...ctx,
        op: {
          ...op,
          // override the target path with the prefix removed
          path,
        },
      })
    }
  }

  /**
   * Vanilla new connection each time
   * Can be used outside of React
   */
  const backendRequest = createTRPCClient<ServiceAppRouter>({
    links: [
      ServiceLink,
      loggerLink({
        enabled: (opts) =>
          showLogs ||
          (opts.direction === 'down' && opts.result instanceof Error),
        colorMode: 'ansi',
      }),
    ],
  })
  /**
   * TRPC/React-Query hook
   *
   * @returns
   */
  const backendHook = createTRPCReact<ServiceAppRouter>()

  const backendHookNext = createTRPCNext<ServiceAppRouter>({
    config() {
      return {
        links: [ServiceLink],
      }
    },
    ssr: false,
  })

  const trpcClient = backendHook.createClient({
    links: [ServiceLink],
  })

  const queryClient = new QueryClient({})

  // /**
  //  * A wrapper for your app that provides the TRPC context.
  //  * Use only in _app.tsx
  //  */
  function TRPCProvider(props: { children: React.ReactNode }) {
    return (
      <QueryClientProvider client={queryClient}>
        <backendHook.Provider client={trpcClient} queryClient={queryClient}>
          {props.children}
        </backendHook.Provider>
      </QueryClientProvider>
    )
  }

  return {
    backendRequest,
    backendHook,
    backendHookNext,
    TRPCProvider,
    queryClient,
  }
}
