import type { FC } from 'react'
import React, { useCallback, useEffect, useRef } from 'react'
import { useUI } from '@contexts/ui/ui.context'
import { animated, useSpring } from '@react-spring/web'
import { fadeInOut } from '@utils/motion/fade-in-out'
import { zoomOutIn } from '@utils/motion/zoom-out-in'
import useOnClickOutside from '@utils/use-click-outside'
import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from 'body-scroll-lock'
import clsx from 'clsx'

interface ModalProps {
  open?: boolean
  children?: React.ReactNode
  onClose: () => void
  dismissible: boolean
  rootClassName?: string
  useBlurBackdrop?: boolean
  containerClassName?: string
  variant?: 'center' | 'bottom'
}
type DivElementRef = React.MutableRefObject<HTMLDivElement>

// Variant based classes for modal root, container & close btn
const rootClasses = {
  center: 'p-4 md:p-5',
  bottom: 'p-5 pb-0',
}

const containerClasses = {
  center: 'h-auto max-h-full top-1/2 -translate-y-1/2',
  bottom: 'h-full max-h-70vh bottom-0 rounded-ts-2xl rounded-te-2xl',
}

export const Modal: FC<ModalProps> = ({
  children,
  open,
  onClose,
  rootClassName,
  useBlurBackdrop,
  containerClassName,
  variant = 'center',
  dismissible = true,
}) => {
  const { closeModal, modalTitle, isFullScreenModal } = useUI()
  const modalRootRef = useRef() as DivElementRef
  const modalInnerRef = useRef() as DivElementRef

  const style = useSpring(fadeInOut(0.25))
  const zoom = useSpring(zoomOutIn(0.25))

  useOnClickOutside(modalInnerRef, () => {
    if (dismissible) {
      // closeModal()
    }
  })

  const escFunction = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Escape' && dismissible) {
      closeModal()
    }
  }, [])

  useEffect(() => {
    globalThis.addEventListener('keydown', escFunction, false)

    return () => {
      globalThis.removeEventListener('keydown', escFunction, false)
    }
  }, [escFunction])

  useEffect(() => {
    if (modalInnerRef.current) {
      if (open) {
        disableBodyScroll(modalInnerRef.current)
      } else {
        enableBodyScroll(modalInnerRef.current)
      }
    }

    return () => {
      clearAllBodyScrollLocks()
    }
  }, [open])

  return (
    <>
      {open && (
        <animated.div
          ref={modalRootRef}
          key="modal"
          style={style}
          className={clsx(
            'modal-root fixed inset-0 z-50 min-w-[400px] overflow-y-auto overflow-x-hidden bg-black bg-opacity-70',
            useBlurBackdrop && 'backdrop-blur-sm backdrop-filter',
            rootClasses[variant],
            rootClassName,
          )}
        >
          <animated.div style={zoom} className="">
            <div
              className={clsx(
                'absolute w-screen -translate-x-3 shadow-xl md:left-1/2 md:w-auto md:-translate-x-1/2 md:transform',
                containerClasses[variant],
                containerClassName,
              )}
            >
              <div
                ref={modalInnerRef}
                className={clsx('rounded-lg bg-white shadow-lg', {
                  'ml-4 h-[96vh] w-[96vw]': isFullScreenModal,
                })}
              >
                {dismissible && (
                  <div className="border-b border-slate-200 px-5 py-3">
                    <div className="flex items-center justify-between">
                      <div className="font-semibold text-slate-800">
                        {modalTitle}
                      </div>
                      <button
                        className="text-slate-400 hover:text-slate-500"
                        onClick={(e) => {
                          e.stopPropagation()
                          onClose()
                        }}
                      >
                        <div className="sr-only">Close</div>
                        <svg className="h-4 w-4 fill-current">
                          <path d="M7.95 6.536l4.242-4.243a1 1 0 111.415 1.414L9.364 7.95l4.243 4.242a1 1 0 11-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 01-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 011.414-1.414L7.95 6.536z" />
                        </svg>
                      </button>
                    </div>
                  </div>
                )}
                {children}
              </div>
            </div>
          </animated.div>
        </animated.div>
      )}
    </>
  )
}
