import { useZodFormHook } from '@goatlab/react-zod-form'
import { reportSchema, ReportType } from '../schema/useReportSchema'

type ReportProps = {
  defaultValues?: ReportType
}

export const useReportForm = ({ defaultValues }: ReportProps) => {
  const formHook = useZodFormHook({
    schema: reportSchema,
    defaultValues: {
      reason: defaultValues?.reason || '',
      postId: defaultValues?.postId || '',
      accountId: defaultValues?.accountId || '',
      commentId: defaultValues?.commentId || '',
    },
  })

  return { formHook }
}
