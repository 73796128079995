import { useCallback, useState } from 'react'
import { useUIStore } from '../ui/ui.context'

export const useQueryRefresh = (promiseFunctions: (() => Promise<any>)[]) => {
  const [refreshing, setRefreshing] = useState(false)
  const { setAppLoading } = useUIStore()

  const handleRefresh = useCallback(async () => {
    setRefreshing(true)
    setAppLoading(true)
    await Promise.all(
      promiseFunctions.map((promiseFunction) => promiseFunction()),
    )

    setAppLoading(false)
    setRefreshing(false)
  }, [promiseFunctions])

  return { refreshing, handleRefresh }
}
