import { useCallback } from 'react'
import { useQueryRefresh } from '@sodium/shared-frontend-schemas'
import { UseHomePostHook } from './useHomePostFeed.model'
import { useHomeFeedQuery } from '../api/posts/homeFeed/useHomeFeedQuery'
import { useAccountFeedQuery } from '../api/posts/accountFeed/useAccountFeedQuery'
import { useUserStoryQuery } from '../api/stories/useUserStoryQuery'

export const useHomePostFeed = ({
  accountId,
  backendHook,
}: UseHomePostHook) => {
  const utils = backendHook.useUtils()

  const getHomeFeedHook = useHomeFeedQuery({
    backendHook: backendHook!,
  })

  const getAccountFeedHook = useAccountFeedQuery({
    visibleAccountId: accountId,
    backendHook,
  })

  const useStories = useUserStoryQuery({
    backendHook,
  })

  const { refreshing, handleRefresh } = useQueryRefresh([
    getHomeFeedHook.refetch,
    useStories.refetch,
    utils.backend.chats.getUnreadConversationsCount.refetch,
    utils.backend.friendship.getFriendshipNotificationsCount.refetch,
    utils.backend.posts.getPostComments.invalidate,
    utils.backend.posts.getAccountsThatLikedPost.invalidate,
  ])

  const { refreshing: refreshingAccount, handleRefresh: handleRefreshAccount } =
    useQueryRefresh([getAccountFeedHook.refetch])

  const loadMore = useCallback(() => {
    getHomeFeedHook.fetchNextPage()
  }, [])

  const loadMoreAccount = useCallback(() => {
    getAccountFeedHook.fetchNextPage()
  }, [getAccountFeedHook])

  return {
    getHomeFeedHook,
    getAccountFeedHook,
    refreshing,
    refreshingAccount,
    handleRefreshAccount,
    handleRefresh,
    loadMore,
    loadMoreAccount,
  }
}
