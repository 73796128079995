import { z } from 'zod'

export const countryAndLanguageInputSchema = z.object({
  country: z.string().min(1, { message: 'Country is required' }).optional(),
  language: z.string().min(1, { message: 'Language is required' }).optional(),
})

export type CountryAndLanguageType = z.infer<
  typeof countryAndLanguageInputSchema
>
