import { CommonLogger } from '@goatlab/js-utils'
import type { BackendHook } from '../../../types/router'
import { getPostCommentsQueryInput } from './getPostCommentsQueryInput'

export const useToggleCommentLike = ({
  backendHook,
  logger,
}: {
  backendHook: BackendHook
  logger: CommonLogger
}) => {
  const utils = backendHook.useUtils()

  return backendHook.backend.comments.toggleCommentLike.useMutation({
    onMutate: async (newLike) => {
      const postCommentQueryInput = getPostCommentsQueryInput({
        postId: newLike.postId,
      })
      let userLikedComment: boolean = false
      await utils.backend.posts.getPostComments.cancel(postCommentQueryInput)

      utils.backend.posts.getPostComments.setInfiniteData(
        postCommentQueryInput,
        (previousData) => {
          if (!previousData?.pages?.length) {
            return { pageParams: [], pages: [] }
          }

          previousData.pages = previousData.pages.map((page) => {
            page.data.forEach((comment) => {
              if (comment.id === newLike.commentId) {
                userLikedComment = !!comment.liked

                comment.reactionsCount =
                  (Number(comment.reactionsCount) || 0) +
                  (comment.liked ? -1 : 1)
                comment.liked = !comment.liked
              }
            })

            return page
          })

          return previousData
        }
      )

      return { userLikedComment }
    },
    onError: (err, newLike, context) => {
      logger.error(err)
      const postCommentQueryInput = getPostCommentsQueryInput({
        postId: newLike.postId,
      })

      utils.backend.posts.getPostComments.setInfiniteData(
        postCommentQueryInput,
        (previousData) => {
          if (!previousData?.pages?.length) {
            return { pageParams: [], pages: [] }
          }

          previousData.pages = previousData.pages.map((page) => {
            page.data.forEach((comment) => {
              if (comment.id === newLike.commentId) {
                comment.reactionsCount =
                  (Number(comment.reactionsCount) || 0) +
                  (!context?.userLikedComment ? -1 : 1)
                comment.liked = context?.userLikedComment ?? false
              }
            })

            return page
          })

          return previousData
        }
      )
    },
  })
}
