import { BackendHook } from '../../../types/router'
import { getAccountsThatLikedPostInput } from './getAccountThatLikedPostInput'

export const useGetAccountsThatLikedPost = ({
  postId,
  backendHook,
}: {
  backendHook: BackendHook
  postId?: string
}) : ReturnType<BackendHook['backend']['posts']['getAccountsThatLikedPost']['useInfiniteQuery']>  => {
  const input = getAccountsThatLikedPostInput({ postId })
  const enabled = !!postId

  return backendHook.backend.posts.getAccountsThatLikedPost.useInfiniteQuery(
    input,
    {
      enabled,
      getNextPageParam: (lastPage) => lastPage?.nextPage,
    }
  )
}
